<!-- eslint-disable vue/html-self-closing -->
<template>
	<div>
		<b-modal id="main-image-modal" size="lg" ok-only>
			<img
					v-if="mainImageModalUrl"
					:src="mainImageModalUrl"
					alt="main image"
					class="modal-image mx-auto"
			/>
		</b-modal>
		<b-overlay :show="showLoading" rounded="sm">
			<form-wizard
					color="#7367F0"
					:title="null"
					:subtitle="null"
					:finish-button-text="t.t('global.submit')"
					:back-button-text="t.t('global.next')"
					class="steps-transparent mb-3"
					@on-complete="formSubmitted"
			>
				<tab-content
						:title="t.t('products.basic-information')"
						icon="feather icon-info"
				>
					<b-row>
						<b-col md="4">
							<b-form-group
									:label="t.t('products.name-geo')"
									label-for="i-name-geo"
							>
								<b-form-input
										id="i-geo"
										v-model="basicData.nameGeo"
										:placeholder="t.t('products.name-geo')"
								/>
							</b-form-group>
						</b-col>
						<b-col md="4">
							<b-form-group
									:label="t.t('products.name-eng')"
									label-for="i-name-eng"
							>
								<b-form-input
										id="i-eng"
										v-model="basicData.nameEng"
										:placeholder="t.t('products.name-eng')"
								/>
							</b-form-group>
						</b-col>
						<b-col md="4">
							<b-form-group :label="t.t('products.sku')" label-for="i-name-eng">
								<b-form-input
										id="i-eng"
										v-model="basicData.upcOrSku"
										:placeholder="t.t('products.sku')"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="t.t('products.category')"
									label-for="i-name-eng"
							>
								<b-form-select v-model="selectedCategory">
									<template #first>
										<b-form-select-option :value="null" disabled>
											-- {{ t.t('products.please-chose') }}--
										</b-form-select-option>
									</template>
									<b-form-select-option-group
											v-for="cat in productCategories"
											:key="cat.id + cat.label"
											:label="cat.label"
									>
										<b-form-select-option
												v-for="subcat in cat.children"
												:key="subcat.id + subcat.label"
												:value="subcat"
												:label="subcat.label"
										>
											-->
											{{ subcat.label }}
										</b-form-select-option>
									</b-form-select-option-group>
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									v-if="!selectedCategory"
									:label="t.t('products.generic-category')"
									label-for="i-name-eng"
							>
								<b-form-select v-model="selectedGenericCategory">
									<b-form-select-option :value="null">
										-- {{ t.t('products.please-chose') }}--
									</b-form-select-option>
								</b-form-select>
							</b-form-group>
							<b-form-group
									v-if="selectedCategory"
									label="Generic Categories"
									label-for="i-name-eng"
							>
								<b-form-select
										v-model="selectedGenericCategory"
										@change="onChangeCategory"
								>
									<template #first>
										<b-form-select-option :value="null" disabled>
											-- Please select an option --
										</b-form-select-option>
									</template>
									<b-form-select-option
											v-for="gencat in selectedCategory.children"
											:key="gencat.id + gencat.label"
											:value="gencat"
											:label="gencat.label"
									>
										{{ gencat.label }}
									</b-form-select-option>
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col v-if="branches.length" sm="4">
							<b-form-group
									:label="t.t('products.branches')"
									label-for="i-name-eng"
							>
								<v-select
										v-model="selectedBranches"
										:options="branches"
										multiple
										chips
										@input="onChangeBranches"
								>
									<template slot="selected-option" slot-scope="option">
										{{ option.nameGeo + ' / ' + option.nameEng }}
									</template>
									<template slot="option" slot-scope="option">
										{{ option.nameGeo + ' / ' + option.nameEng }}
									</template>
								</v-select>
							</b-form-group>
						</b-col>
						<hr
								class="mx-auto my-1 w-75"
								style="height: 1px; background: #e2e2e2"
						/>
						<b-col sm="4">
							<b-form-group
									:label="t.t('products.service-type')"
									label-for="i-name-eng"
							>
								<b-form-input
										id="i-eng"
										v-model="selectedServiceType"
										disabled
										:placeholder="t.t('products.service-type')"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="t.t('products.gift-type')"
									label-for="i-name-eng"
							>
								<b-form-input
										id="i-eng"
										v-model="selectedGiftType"
										disabled
										:placeholder="t.t('products.gift-type')"
								/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="t.t('products.comission')"
									label-for="i-name-eng"
							>
								<b-form-input
										id="i-eng"
										v-model="selectedCommissionFee"
										disabled
										:placeholder="t.t('products.comission')"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<hr
							class="mx-auto my-1 w-75"
							style="height: 1px; background: #e2e2e2"
					/>
					<b-row>
						<b-col cols="12">
							<h6>Location</h6>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="t.t('products.cities')"
									label-for="i-name-eng"
							>
								<v-select
										v-model="selectedCity"
										:options="regions"
										@input="onCityChange"
								>
									<template slot="selected-option" slot-scope="option">
										{{ option.cityNameGeo + ' / ' + option.cityNameEng }}
									</template>
									<template slot="option" slot-scope="option">
										{{ option.cityNameGeo + ' / ' + option.cityNameEng }}
									</template>
								</v-select>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="t.t('products.districs')"
									label-for="i-name-eng"
							>
								<v-select
										v-if="selectedCity"
										v-model="selectedDistrict"
										:options="selectedCity.districts"
										@input="onDistrictChange"
								>
									<template slot="selected-option" slot-scope="option">
										{{
											option.districtNameGeo + ' / ' + option.districtNameEng
										}}
									</template>
									<template slot="option" slot-scope="option">
										{{
											option.districtNameGeo + ' / ' + option.districtNameEng
										}}
									</template>
								</v-select>
								<v-select v-else disabled/>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group
									:label="t.t('products.location')"
									label-for="i-name-geo"
							>
								<b-form-input
										id="i-geo"
										v-model="basicData.location"
										:placeholder="t.t('products.location-placeholder')"
								/>
								<b-form-checkbox v-model="basicData.isLocationFree" plain>
									{{ t.t('products.location-free') }}
								</b-form-checkbox>
							</b-form-group>
						</b-col>
					</b-row>
				</tab-content>
				<tab-content
						:title="t.t('products.characteristics')"
						icon="feather icon-info"
				>
					<b-col>
						<div>
							<div>
								<b-row>
									<b-col sm="4">
										<b-form-group
												:label="t.t('products.target-genders')"
												label-for="i-name-eng"
										>
											<b-form-select
													v-model="basicData.targetGender"
													:placeholder="t.t('products.target-genders')"
													:options="gendersOption"
													@change="(e) => onChangeGender(e)"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="4">
										<b-form-group
												:label="t.t('products.age-minmax')"
												label-for="i-name-eng"
												class="mt-1"
										>
											<vue-slider
													v-model="ageRange"
													class="mb-2"
													@change="(e) => onChangeAgeRange(e)"
											/>
										</b-form-group>
									</b-col>
									<b-col sm="4" class="d-flex">
										<b-form-group
												:label="t.t('products.min-age')"
												label-for="i-name-eng"
												class="mr-1"
										>
											<b-form-input
													id="i-eng"
													v-model="ageRange[0]"
													type="number"
													:placeholder="t.t('products.target-age')"
													@change="(e) => onChangeAgeRange(e)"
											/>
										</b-form-group>
										<b-form-group
												:label="t.t('products.max-age')"
												label-for="i-name-eng"
										>
											<b-form-input
													id="i-eng"
													v-model="ageRange[1]"
													type="number"
													:placeholder="t.t('products.max-age')"
													@change="(e) => onChangeAgeRange(e)"
											/>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
							<hr
									v-if="productAttributes.length"
									class="mx-auto my-1 w-75"
									style="height: 1px; background: #e2e2e2"
							/>
							<div
									v-if="productAttributes.length"
									class="d-flex mt-2 justify-content-center flex-wrap"
							>
								<b-form-group
										v-for="attribute in productAttributes"
										:key="attribute.id"
										:label="attribute.nameEng + ' / ' + attribute.nameGeo"
										label-for="i-name-eng"
										class="mr-1 p-1 border rounded"
								>
									<b-form-input
											id="i-eng"
											v-model="attribute.value.valueGeo"
											type="text"
											:placeholder="t.t('global.geo-value')"
											@change="
											(e) => onChangeAttributeName(e, attribute.id, 'geo')
										"
									/>
									<b-form-input
											id="i-eng"
											v-model="attribute.value.valueEng"
											type="text"
											:placeholder="t.t('global.eng-value')"
											@change="
											(e) => onChangeAttributeName(e, attribute.id, 'eng')
										"
									/>
								</b-form-group>
							</div>
						</div>
					</b-col>
				</tab-content>
				<tab-content
						v-if="isConfigurable"
						:title="t.t('products.configurations')"
						icon="feather icon-settings"
				>
					<b-col>
						<div class="configure-wrapper">
							<div class="mr-1">
								<chrome-picker v-model="selectedColor"/>
								<b-button
										class="mt-2 w-100"
										size="sm"
										:disabled="Object.keys(selectedColor).length === 0"
										variant="primary"
										@click="addColor"
								>
									{{ t.t('products.add-color') }}
								</b-button>
							</div>
							<div class="colors-container w-100">
								<b-card
										v-for="(product, productIndex) in configurationsData"
										:key="productIndex"
										class="position-relative"
								>
									<div
											class="config-header-bubble"
											:style="`background:${product.colorCode}`"
									></div>
									<div class="file-wrapper mt-2">
										<b-form-group
												:label="t.t('products.select-main')"
												label-for="i-name-eng"
												class="w-50"
										>
											<b-form-file
													:placeholder="t.t('products.select-main')"
													drop-placeholder="Drop file here..."
													@input="
													(e) =>
														handleImageUploadChange(e, product, productIndex)
												"
											/>
											<div v-if="product.mainImage" class="card image-wrapper">
												<div>
													<img
															v-if="product.mainImage"
															:src="showMainImage(product.mainImage)"
															alt="main image"
															class="rounded my-1 thumb-image"
															@click="
															handleMainImageModal(
																showMainImage(product.mainImage)
															)
														"
													/>
												</div>
											</div>
										</b-form-group>
										<b-form-group
												:label="t.t('products.select-gallery')"
												label-for="i-name-eng"
												class="ml-2 w-50"
										>
											<b-form-file
													:placeholder="t.t('products.select-gallery')"
													drop-placeholder="Drop file here..."
													multiple
													@change="(e) => onChangeProductGallery(e, product)"
											/>
											<div
													v-if="product.gallery.length"
													class="image-gallery-preview mt-1"
											>
												<div
														v-for="img in product.gallery"
														:key="img.id"
														class="position-relative"
												>
													<a
															id="boxclose"
															class="boxclose"
															@click="
															removeImageFromGallery(
																img,
																product.gallery,
																product
															)
														"
													></a>
													<img
															:src="showMainImage(img)"
															alt="main image"
															class="rounded my-1 thumb-image"
															@click="handleMainImageModal(showMainImage(img))"
													/>
												</div>
											</div>
										</b-form-group>
									</div>
									<hr
											class="mx-auto my-1 w-75"
											style="height: 1px; background: #e2e2e2"
									/>
									<!-- {{product}} -->
									<p v-if="computedEditButton(product)">
										<b-button pill size="sm" variant="primary" @click="pushConfigurationToEdited(product)">
											{{ t.t('products.edit-config') }}
										</b-button>
									</p>
									<div v-if="!computedEditButton(product)">
										<b-form-group :label="t.t('products.sizes')">
											<div class="size-input-container">
												<b-form-input
														id="size-geo"
														v-model="tempSizeGeo"
														type="text"
														:placeholder="t.t('products.size-geo')"
												/>
												<b-form-input
														id="size-eng"
														v-model="tempSizeEng"
														type="text"
														:placeholder="t.t('products.size-eng')"
												/>
												<b-button
														size="sm"
														variant="primary"
														@click="handleSizeClick(productIndex)"
												>
													{{ t.t('global.add') }}
												</b-button>
											</div>
											<hr
													class="mx-auto my-1 w-75"
													style="height: 1px; background: #e2e2e2"
											/>
										</b-form-group>
										<div
												v-for="(size, index) in product.options"
												:key="index"
												class="mt-2"
										>

											<h2>
												{{
													`${t.t('products.size')} ${
															size.sizeOptionEng + ' / ' + size.sizeOptionGeo
													}`
												}}
											</h2>
											<div class="d-flex">
												<b-form-group
														label="SKU"
														label-for="i-name-eng"
														class="mr-2"
												>
													<b-form-input
															id="i-eng"
															v-model="product.options[index].upcOrSku"
															:placeholder="t.t('products.name')"
													/>
												</b-form-group>
												<b-form-group label="Quantity" label-for="i-name-eng">
													<b-form-input
															id="i-eng"
															v-model="product.options[index].count"
															:placeholder="t.t('products.count')"
													/>
												</b-form-group>
											</div>
										</div>
										<hr
												v-if="product.options.length"
												class="mx-auto my-1 w-75"
												style="height: 1px; background: #e2e2e2"
										/>
									</div>
									<div>
										<b-button
												variant="danger"
												size="sm"
												class="remove-button"
												@click="onDeleteConfiguration(productIndex, product)"
										>
											{{ t.t('global.remove') }}
										</b-button>
									</div>
								</b-card>
							</div>
						</div>
					</b-col>
				</tab-content>
				<tab-content
						:title="t.t('products.product-description')"
						icon="feather icon-file-text"
				>
					<b-row>
						<b-col md="12">
							<div class="d-flex">
								<b-form-group :label="t.t('products.description-eng')" class="w-50 mr-1">
									<b-form-textarea
											id="textarea-state"
											v-model="basicData.descriptionEng"
											:placeholder="t.t('products.description-eng')"
											rows="2"
									/>
								</b-form-group>
								<b-form-group :label="t.t('products.description-geo')" class="w-50">
									<b-form-textarea
											id="textarea-state"
											v-model="basicData.descriptionGeo"
											:placeholder="t.t('products.description-geo')"
											rows="2"
									/>
								</b-form-group>
							</div>
							<hr
									class="mx-auto my-1 w-75"
									style="height: 1px; background: #e2e2e2"
							/>
							<div class="d-flex">
								<b-form-group :label="t.t('products.terms-eng')" class="w-50 mr-1">
									<b-form-textarea
											id="textarea-state"
											v-model="basicData.useTermsEng"
											:placeholder="t.t('products.terms-eng')"
											rows="2"
									/>
								</b-form-group>
								<b-form-group :label="t.t('products.terms-geo')" class="w-50">
									<b-form-textarea
											id="textarea-state"
											v-model="basicData.useTermsGeo"
											:placeholder="t.t('products.terms-geo')"
											rows="2"
									/>
								</b-form-group>
							</div>
						</b-col>
					</b-row>
					<hr
							class="mx-auto my-1 w-75"
							style="height: 1px; background: #e2e2e2"
					/>
					<b-row>
						<b-col md="4">
							<b-form-group :label="t.t('products.product-visibility')">
								<flat-pickr
										v-model="dateRange"
										:placeholder="t.t('products.product-visibility')"
										class="form-control"
										:config="{
										enableTime: true,
										dateFormat: 'Y-m-d H:i:ss',
										mode: 'range',
									}"
								/>
							</b-form-group>
						</b-col>
						<b-col md="8">
							<b-form-group :label="t.t('products.sale')">
								<b-form-checkbox
										v-model="basicData.isSale"
										checked="true"
										name="check-button"
										switch
										inline
								>
									{{ t.t('products.sale') }}
								</b-form-checkbox>
							</b-form-group>
							<div class="sales-wrapper">
								<b-form-group :label="t.t('global.type')">
									<b-form-checkbox
											v-model="sale.type"
											:disabled="!basicData.isSale"
											checked="true"
											class="custom-control-primary"
											name="check-button"
											:value="1"
											:unchecked-value="2"
											switch
									>
										<span class="switch-icon-left">
											<feather-icon icon="HashIcon"/>
										</span>
										<span class="switch-icon-right">
											<feather-icon icon="PercentIcon"/>
										</span>
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
										v-if="sale.type === 2"
										:label="t.t('global.value')"
										append="%"
										class="percent-input ml-2 mr-2"
								>
									<b-form-input
											v-model="sale.percent"
											:readonly="!basicData.isSale"
											placeholder="0"
											type="number"
									/>
								</b-form-group>
								<b-form-group
										v-else
										:label="t.t('global.value')"
										append="#"
										class="percent-input ml-2 mr-2"
								>
									<b-form-input
											v-model="sale.fixed"
											:readonly="!basicData.isSale"
											placeholder="0"
											type="number"
									/>
								</b-form-group>
								<b-form-group :label="t.t('products.sale-until')">
									<flat-pickr
											v-model="basicData.saleUntilAt"
											:readonly="!basicData.isSale"
											:disabled="!basicData.isSale"
											:placeholder="t.t('products.sale-until')"
											class="form-control"
											:config="{
												enableTime: true,
												dateFormat: 'Y-m-d H:i:ss',
												mode: 'single',
											}"
									/>
								</b-form-group>
							</div>
						</b-col>
					</b-row>
					<b-col class="mt-2">
						<b-row md="6">
							<b-form-group
									label="Price"
									label-for="i-name-eng"
									class="mr-2 input-wrapper"
							>
								<b-form-input
										id="i-eng"
										v-model="basicData.price"
										placeholder="Name here..."
								/>
							</b-form-group>
							<b-form-group
									v-if="!configurationsData.length"
									class="input-wrapper"
									label="Count"
									label-for="i-name-eng"
							>
								<b-form-input
										id="i-eng"
										v-model="basicData.count"
										placeholder="Name here..."
								/>
							</b-form-group>
							<b-form-group
									v-if="!configurationsData.length"
									class="ml-2"
									label="Select Main Image"
									label-for="i-name-eng"
							>
								<b-form-file
										v-model="basicData.mainImage"
										placeholder="Choose a file or drop it here..."
										drop-placeholder="Drop file here..."
								/>
								<div>
									<img
											v-if="basicData.mainImage"
											:src="showMainImage(basicData.mainImage)"
											alt="main image"
											class="rounded my-1 thumb-image"
											@click="
											handleMainImageModal(showMainImage(basicData.mainImage))
										"
									/>
									<img
											v-if="!basicData.mainImage && tempMainImageUrl"
											:src="tempMainImageUrl"
											alt="main image"
											class="rounded my-1 thumb-image"
									/>
								</div>
							</b-form-group>
							<b-form-group
									v-if="!configurationsData.length"
									class="ml-2"
									label="Select Gallery Images"
									label-for="i-name-eng"
							>
								<b-form-file
										v-model="mainGallery"
										placeholder="Choose a file or drop it here..."
										drop-placeholder="Drop file here..."
										multiple
								/>
								<div
										v-if="mainGallery.length"
										class="image-gallery-preview mt-1"
								>
									<div
											v-for="img in mainGallery"
											:key="img.id"
											class="position-relative"
									>
										<a
												id="boxclose"
												class="boxclose"
												@click="removeImageFromGallery(img, mainGallery)"
										></a>
										<img
												:src="showMainImage(img)"
												alt="main image"
												class="rounded my-1 thumb-image"
												@click="handleMainImageModal(showMainImage(img))"
										/>
									</div>
								</div>
								<div v-if="tempMainGallery" class="image-gallery-preview mt-1">
									<div
											v-for="img in tempMainGallery"
											:key="img.id"
											class="position-relative"
									>
										<a
												id="boxclose"
												class="boxclose"
												@click="removeImageFromGallery(img, tempMainGallery)"
										></a>
										<img
												:src="img.url"
												alt="main image"
												class="rounded my-1 thumb-image"
												@click="handleMainImageModal(showMainImage(img))"
										/>
									</div>
								</div>
							</b-form-group>
						</b-row>
					</b-col>
				</tab-content>
			</form-wizard>
		</b-overlay>
	</div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';

import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import {
	BRow,
	BCol,
	BFormGroup,
	BButton,
	BFormInput,
	BFormFile,
	BFormSelect,
	BFormCheckbox,
	BOverlay,
	BFormTextarea,
	BFormSelectOption,
	BFormSelectOptionGroup,
	BCard,
	BModal,
} from 'bootstrap-vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { mapState, mapActions } from 'vuex';
import flatPickr from 'vue-flatpickr-component';
import { Chrome } from 'vue-color';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';
import objectToFormData from '@/helpers/objectToFormData';
import appendConfigurationsToFormData from '@/helpers/appendConfigurationsToFormData';
import appendUpdateConfigurations from '@/helpers/appendUpdateConfigurations';
import { codeIconInfo } from './code';

export default {
	components: {
		FormWizard,
		BFormCheckbox,
		BButton,
		BFormSelect,
		BModal,
		BCard,
		VueSlider,
		flatPickr,
		BOverlay,
		BFormTextarea,
		'chrome-picker': Chrome,
		TabContent,
		BFormFile,
		vSelect,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		// eslint-disable-next-line vue/no-unused-components
		BFormSelectOption,
		BFormSelectOptionGroup,
		// eslint-disable-next-line vue/no-unused-components
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isApproved: {
			type: Boolean,
			default: false,
		},
		isDublicate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			t: null,
			tempMainImageUrl: '',
			mainImageModalUrl: '',
			tempMainImage: '',
			selectedAttributes: {},
			tempSizeGeo: '',
			tempSizeEng: '',
			show: true,
			selectedCommissionFee: null,
			selectedServiceType: null,
			selectedGiftType: null,

			selectedCategory: null,
			selectedGenericCategory: null,
			selectedBranches: [],
			tempMainGallery: [],
			serviceTypeLabelList: {
				1: 'Experiential',
				2: 'Material',
				3: 'Digital,',
			},
			giftTypeLabelList: {
				1: 'Both',
				2: 'Daily',
				3: 'Holiday',
			},
			basicData: {
				nameEng: '',
				nameGeo: '',
				upcOrSku: '',
				productCategoryId: 0,
				productSubCategoryId: 0,
				productGenericCategoryId: 0,
				branches: [],
				isLocationFree: true,
				location: '23.88422, 23.88422',
				cityId: 0,
				districtId: 0,
				minAge: 25,
				maxAge: 50,
				targetGender: 0,
				attributes: [],
				configurations: [],
				descriptionGeo: '',
				descriptionEng: '',
				useTermsGeo: '',
				useTermsEng: '',
				saleUntilAt: '',
				price: 0,
				mainImage: null,
				gallery: [],
				count: 0,
				isSale: false,
				activeFrom: '',
				activeTo: '',
			},
			//
			sale: {
				type: 2,
				fixed: null,
				percent: null,
			},

			configurationsData: [],
			mainGallery: [],
			selectedDistrict: null,
			selectedCity: null,

			configurationsUpdate: {
				deletedConfigurations: [],
				editedReviewProductConfigurations: [],
				newConfigurations: [],
			},

			gendersOption: [
				{
					text: 'Male',
					value: 1
				},
				{
					text: 'Female',
					value: 2
				},
				{
					text: 'Both',
					value: 3
				},
			],
			ageRange: [25, 50],
			dateRange: null,
			//
			selectedColor: {},
			selectedSizes: [],

			maxDescriptionChars: '120',

			codeIconInfo,
		};
	},
	computed: {
		...mapState('products', [
			'productCategories',
			'productAttributes',
			'branches',
			'regions',
			'showLoading',
			'isConfigurable',
			'product',
		]),
		isThisEdited(product) {
			const destination = this.isApproved
					? 'editedProductConfigurations'
					: 'editedReviewProductConfigurations';
			const index = this.configurationsUpdate[destination].findIndex(e => e.id === product.id);
			return index > -1;
		},
	},
	watch: {
		configurationsUpdate(newVal) {
			console.log('change', newVal);
		},
		'basicData.mainImage': function (newVal) {
			if (this.isEdit && newVal && newVal.type) {
				console.log('editing main image');
				this.basicData.mainImageUpdate = {
					isPreviousDeleted: true,
					newImage: newVal,
				};
			}
		},
		mainGallery(newVal) {
			if (this.isEdit) {
				this.basicData.galleryImageUpdate.newGalleryImages = newVal;
			}
		},
		product(newVal) {
			console.log('product to edit ', newVal);
			if (this.isEdit) {
				this.handleEditProdutFields(newVal);
			}
		},
		// eslint-disable-next-line func-names
		selectedGenericCategory(newVal) {
			if (newVal.productSubCategoryId) {
				this.getProductAttributes({ id: newVal.productSubCategoryId });
			}
		},
		dateRange(value) {
			const payload = value.split(' to ');
			// eslint-disable-next-line prefer-destructuring
			this.basicData.activeFrom = payload[0];
			// eslint-disable-next-line prefer-destructuring
			this.basicData.activeTo = payload[1];
		},
	},
	created() {
		this.t = this.$i18n;
	},
	methods: {
		onChangeProductGallery(e, product) {
			e.target.files.forEach((i) => {
				product.gallery.push(i);
			});
			if (!product.id) return;

			const destination = this.isApproved
					? 'editedProductConfigurations'
					: 'editedReviewProductConfigurations';

			const indexOfObject = this.configurationsUpdate[destination].findIndex(
					(object) => object.configurationId === product.id
			);
			if (indexOfObject > -1) {
				this.configurationsUpdate[destination][indexOfObject].galleryUpdate.newGalleryImages = [...e.target.files];
			} else {
				this.configurationsUpdate[destination].push({
					configurationId: product.id,
					options: product.options,
					mainImageUpdate: {
						isPreviousDeleted: false,
						newImage: null,
					},
					galleryUpdate: {
						deletedImages: [],
						newGalleryImages: [...e.target.files],
					},
				})
			}
			console.log(this.configurationsUpdate[destination]);
		},
		computedEditButton(product) {
			if (!product.id) return false;
			const destination = this.isApproved
					? 'editedProductConfigurations'
					: 'editedReviewProductConfigurations';
			return this.configurationsUpdate[destination].findIndex(e => e.configurationId === product.id) < 0
		},
		removeImageFromGallery(img, gallery, product = {}) {
			const index = gallery.indexOf(img);
			if (index > -1) {
				gallery.splice(index, 1);
			}

			if (gallery === this.tempMainGallery) {
				this.basicData.galleryImageUpdate.deletedImages.push(img.id);
			}
			const destination = this.isApproved
					? 'editedProductConfigurations'
					: 'editedReviewProductConfigurations';
			if (img.id) {
				const findIndex =
						this.configurationsUpdate[destination].findIndex(
								(x) => x.configurationId === product.id
						);

				if (findIndex > -1) {
					this.configurationsUpdate[destination][
							findIndex
							].galleryUpdate.deletedImages.push(img.id);
				} else {
					this.configurationsUpdate[destination].push({
						configurationId: product.id,
						options: product.options,
						mainImageUpdate: {
							isPreviousDeleted: false,
							newImage: null,
						},
						galleryUpdate: {
							deletedImages: [img.id],
							newGalleryImages: [],
						},
					});
				}
			}
		},
		handleMainImageModal(imageUrl) {
			this.mainImageModalUrl = imageUrl;
			this.$bvModal.show('main-image-modal');
		},
		...mapActions('products', [
			'getProductAttributes',
			'editProduct',
			'createProduct',
		]),
		onDeleteConfiguration(id, product) {
			if (product.id) {
				this.configurationsUpdate.deletedConfigurations.push(product.id);
			}
			const indexOfObject = this.configurationsData.findIndex(
					(object, index) => index === id
			);
			this.configurationsData.splice(indexOfObject, 1);
		},
		onCityChange() {
			this.selectedDistrict = null;
			this.basicData.cityId = this.selectedCity.cityId;
		},

		resetTempSizeNames() {
			this.tempSizeGeo = '';
			this.tempSizeEng = '';
		},
		handleSizeClick(index) {
			this.selectedSizes.push({
				sizeGeo: this.tempSizeGeo,
				sizeEng: this.tempSizeEng,
			});

			this.resetTempSizeNames();

			this.onChangeSizes(this.selectedSizes, index);
			this.selectedSizes = [];
			// TODO: this fix is temporaty and need to be reworked when working on editing the product.

			// this.onChangeSizes()
		},
		onChangeSizes(e, index) {
			// eslint-disable-next-line array-callback-return
			e.map((val) => {
				const indexx = this.configurationsData[index].options.findIndex(
						(object) => object.sizeOptionGe === val.sizeGeo
				);
				if (indexx === -1) {
					if (
							!this.configurationsData[index].options.some(
									(b) => b.sizeOptionGeo === val.sizeGeo
							)
					) {
						this.configurationsData[index].options.push({
							sizeOptionGeo: val.sizeGeo,
							sizeOptionEng: val.sizeEng,
							upcOrSku: '',
							count: null,
						});
					}
				}
			});
		},
		pushConfigurationToEdited(configuration) {
			const destination = this.isApproved
					? 'editedProductConfigurations'
					: 'editedReviewProductConfigurations';
			this.configurationsUpdate[destination].push({
				configurationId: configuration.id,
				options: configuration.options,
				mainImageUpdate: {
					isPreviousDeleted: false,
					newImage: null,
				},
				galleryUpdate: {
					deletedImages: [],
					newGalleryImages: [],
				},
			});
		},
		onChangeBranches() {
			if (this.selectedBranches.length === 0) {
				this.basicData.branches = [];
			} else {
				// this.selectedBranches.forEach((e) => {
				// 	this.basicData.branches.push(e.id);
				// });
				this.basicData.branches = this.selectedBranches.map((e) => e.id);
			}
		},
		onChangeGender(e) {
			this.basicData.targetGender = e;
		},
		onDistrictChange() {
			this.basicData.districtId = this.selectedDistrict.districtId;
		},
		onChangeAgeRange(e) {
			this.basicData.minAge = e[0];
			this.basicData.maxAge = e[1];
		},
		onChangeAttributeName(e, id, lang) {
			// check if array already has the value, if it does set the other language
			const index = this.basicData.attributes?.findIndex(
					(object) => object.id === id
			);
			if (index > -1) {
				if (lang === 'geo') {
					this.basicData.attributes[index].valueGeo = e;
				} else {
					this.basicData.attributes[index].valueEng = e;
				}
			} else {
				this.basicData.attributes.push({
					id,
					valueEng: lang === 'eng' ? e : '',
					valueGeo: lang === 'geo' ? e : '',
				});
			}
		},
		handleImageUploadChange(e, product, index) {
			if (this.isEdit && product.id) {
				// check if this el has id. if it does set as new image
				// and set the old one as removed;
				const findIndex =
						this.configurationsUpdate.editedReviewProductConfigurations.findIndex(
								(x) => x.configurationId === product.id
						);
				if (findIndex > -1) {
					//
					this.configurationsUpdate.editedReviewProductConfigurations[
							findIndex
							].mainImageUpdate = {
						isPreviousDeleted: true,
						newImage: e,
					};
				} else {
					this.configurationsUpdate.editedReviewProductConfigurations.push({
						configurationId: product.id,
						options: product.options,
						mainImageUpdate: {
							isPreviousDeleted: true,
							newImage: e,
						},
						galleryUpdate: {
							deletedImages: [],
							newGalleryImages: [],
						},
					});
				}
			}
			this.configurationsData[index].mainImage = e;
		},
		showMainImage(e) {
			if (e.type) {
				return URL.createObjectURL(e);
			}
			return e.url;
		},
		onChangeCategory() {
			this.selectedCommissionFee =
					this.selectedGenericCategory.emotyCommissionFeePercent;
			this.selectedGiftType =
					this.giftTypeLabelList[this.selectedGenericCategory.giftType];
			this.selectedServiceType =
					this.serviceTypeLabelList[this.selectedGenericCategory.serviceType];
			this.basicData.productGenericCategoryId = this.selectedGenericCategory.id;
			this.basicData.productCategoryId =
					this.selectedGenericCategory.productCategoryId;
			this.basicData.productSubCategoryId =
					this.selectedGenericCategory.productSubCategoryId;
		},
		addColor() {
			this.configurationsData.push({
				colorCode: this.selectedColor ? this.selectedColor.hex : '#fff',
				mainImage: null,
				gallery: [],
				options: [],
			});
			this.selectedColor = {};
		},
		formSubmitted() {
			// if (this.isEdit) {
			// 	this.configurationsData.forEach((e) => {
			// 		if (e.id) {
			// 			const index =
			// 				this.configurationsUpdate.editedReviewProductConfigurations.findIndex(
			// 					(b) => b.configurationId === e.id
			// 				);
			// 			if (index > -1) {
			// 				this.configurationsUpdate.editedReviewProductConfigurations[
			// 					index
			// 				].galleryUpdate.newGalleryImages = e.gallery;
			// 			}
			// 		}
			// 	});
			// }

			this.configurationsUpdate.newConfigurations =
					this.configurationsData.filter((e) => !e.hasOwnProperty('id'));
			console.log('this ocnfigurations update', this.configurationsUpdate);
			console.log('this ocnfigurations data', this.configurationsData);
			console.log('basic dada', this.basicData);

			const formData = objectToFormData(this.basicData);

			if (this.basicData.isSale) {
				formData.append('sale.type', this.sale.type);
				formData.append('sale.fixed', this.sale.fixed);
				formData.append('sale.percent', this.sale.percent);
			}
			if (!this.isEdit) {
				appendConfigurationsToFormData(
						formData,
						this.configurationsData,
						this.isEdit,
						this.isDublicate
				);
			} else {
				appendUpdateConfigurations(
						formData,
						this.configurationsUpdate,
						this.isApproved
				);
			}

			if (this.configurationsData.length === 0 && !this.isEdit) {
				for (let i = 0; i < this.mainGallery.length; i++) {
					formData.append(`gallery`, this.mainGallery[i]);
				}
			}

			const callType = this.isApproved ? 'products' : 'review-products';
			const routeType = this.isApproved
					? '/approved-products'
					: '/pending-products';

			if (this.isEdit && !this.isDublicate) {
				const id = this.product.id;
				this.editProduct({
					type: callType,
					formData,
					id,
				})
						.then((response) => {
							if (!response.data.error) {
								this.$router.push(routeType);
							}
						});
			} else {
				this.createProduct({
					formData,
				})
						.then((response) => {
							if (!response.data.error) {
								this.$router.push('/pending-products');
							}
						});
			}
		},
		handleEditProdutFields(product) {
			// handle the product edit fields here;
			this.basicData = {
				nameEng: product.nameEng,
				nameGeo: product.nameGeo,
				upcOrSku: product.upcOrSku,
				productCategoryId: product.productCategory.productCategoryId,
				productSubCategoryId: product.productSubCategory.productSubCategoryId,
				productGenericCategoryId:
				product.productGenericCategory.productGenericCategoryId,
				branches: [],
				isLocationFree: product.isLocationFree,
				location: product.location,
				cityId: product.city.cityId,
				districtId: product.district.districtId,
				minAge: product.minAge,
				maxAge: product.maxAge,
				targetGender: product.productTargetGender,
				attributes: [],
				saleUntilAt: product.saleUntilAt,
				isSale: product.isSale,
			};

			this.sale = product.sale;

			this.selectedBranches = product.branches;
			this.onChangeBranches();

			this.productCategories.forEach((e) => {
				e.children.forEach((d) => {
					if (d.id === product.productSubCategory.productSubCategoryId) {
						this.selectedCategory = d;
					}
					d.children.forEach((p) => {
						if (
								p.id === product.productGenericCategory.productGenericCategoryId
						) {
							this.selectedGenericCategory = p;
						}
					});
				});
			});

			this.onChangeCategory();

			this.selectedCity = product.city;

			this.selectedDistrict = product.district;

			this.ageRange = [product.minAge, product.maxAge];
			this.handleProductAttributesOnEdit(product);
			if (!this.isDublicate) {
				this.configurationsData = product.productConfigurations
						? product.productConfigurations
						: [];
			}
			// this.productAttributes = product.productAttributes.slice();
			// this.productAttributes.forEach((e, index) => {
			//   e.value = product.productAttributes[index].value;
			// });

			this.dateRange = `${product.activeFrom} to ${product.activeTo}`;
			this.basicData.descriptionGeo = product.descriptionGeo;
			this.basicData.descriptionEng = product.descriptionEng;
			this.basicData.useTermsGeo = product.useTermsGeo;
			this.basicData.useTermsEng = product.useTermsEng
					? product.useTermsEng
					: '';
			this.basicData.price = product.price;

			this.tempMainImageUrl = product.mainImage ? product.mainImage.url : '';
			this.tempMainGallery = product.gallery ? product.gallery : '';
			this.basicData.mainImageUpdate = {
				isPreviousDeleted: false,
				newImage: null,
			};
			this.basicData.galleryImageUpdate = {
				deletedImages: [],
				newGalleryImages: [],
			};
			this.basicData.count = product.count;
			this.basicData.sale = product.sale ? product.sale : {
				type: null,
				fixed: null,
				percent: null
			};
			this.basicData.activeFrom = product.activeFrom;
			this.basicData.activeTo = product.activeTo;
		},
		handleProductAttributesOnEdit(product) {
			// if (!product.productAttributes) return;
			setTimeout(() => {
				console.log(this.productAttributes);
				product.productAttributes.forEach((e) => {
					const index = this.productAttributes.findIndex(i => i.id === e.id);
					if (index > -1) {
						this.productAttributes[index].value.valueGeo = e.value.valueGeo;
						this.productAttributes[index].value.valueEng = e.value.valueEng;
						this.basicData.attributes.push({
							id: e.id,
							valueGeo: e.value.valueGeo,
							valueEng: e.value.valueEng
						})
					}
				})
			}, 2000)
		},
	},
};
</script>

<style lang="scss" scoped>
.percent-input {
	//width: 30%;
}

.sales-wrapper {
	display: flex;
	align-items: center;
}

.margin-important {
	margin: 0 !important;
}

.input-wrapper {
	width: 15%;
}

.configure-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.colors-container {
	width: 75%;
}

.file-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.target-gender {
	width: 20%;
}

.size-input-container {
	display: flex;
	margin-bottom: 1rem;
}

.size-input-container input {
	max-width: 250px;
	margin-right: 1rem;
}

.config-header-bubble {
	width: 100%;
	height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	border-top-left-radius: 0.428rem;
	border-top-right-radius: 0.428rem;
}

.thumb-image {
	width: auto;
	max-height: 150px;
	cursor: pointer;
	margin-right: 1rem;

	&:hover {
		opacity: 0.8;
	}
}

.modal-image {
	margin: 0 auto;
	max-width: 100%;
	object-fit: cover;
}

#main-image-modal___BV_modal_content_ .modal-body {
	display: flex;
}

.remove-button {
	display: block !important;
	margin-left: auto !important;
}

a.boxclose {
	position: absolute;
	top: 0;
	z-index: 99;
	left: -7.5px;
	cursor: pointer;
	color: darkgray;
	border: 1px solid #aeaeae;
	border-radius: 100%;
	background: white;
	font-size: 14px;
	font-weight: bold;
	display: inline-block;
	line-height: 0px;
	padding: 9px 5px;
}

a.boxclose:hover {
	background: lightcoral;
	color: white;
}

.boxclose:before {
	content: '×';
}

.image-gallery-preview {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.image-gallery-preview img {
	max-height: 100px;
}
</style>
